import app from './app';
import config from '../config';
import navigation from './navigation';
import { getParams, getHash } from './utils';
import { listenVKWebAppUpdateConfig, apiGetGroupById, apiGetUser } from './vk';
import { getData } from './back';


export function go(callback) {

	listenVKWebAppUpdateConfig((data) => {
		let scheme = data.scheme ? data.scheme : 'client_light';
		app.setState({
			scheme: scheme
		})		
	})

	window.onpopstate = (e) => {
		navigation.nativeGoBack()
		return;
	}

	const params = getParams()
	const hash = getHash()

	config.set({
		params: params,
		hash: hash,
		app_id: +params.vk_app_id
	}, () => {
		if (config.admin_app.indexOf(+params.vk_user_id) !== -1) {
			startApp()
		}
	})
}

export function startApp(callback = () => {}) {
	const timestamp = Math.floor(Date.now() / 1000) //текущий таймстамп
	getData(20, 0, timestamp, (response) => {
		const { data } = response
		const uids = data.map(({uid}) => { return uid })
		const gids = data.map(({gid}) => { return gid })

		apiGetGroupById(config.access_token, gids, (groups) => {
			apiGetUser(config.access_token, uids, (users) => {
				app.setState({
					isStartInGroup: isStartInGroup(), //сервис запущен из группы
					isAdminInGroup: isAdminInGroup(), //пользователь администратор в сообществе
					goStartPanel: false,
					isLoad: true,
					header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true, //показываем или скриваем хедер при кастомном хедере на десктопе 
					data: data,
					timestamp:timestamp,
					apiUsersData: (users) ? users : [],
					apiGroupsData:  (groups) ? groups : [],
					count: 20,
					offset: 0,
				}, () => {
					callback(true)
				})
			})
		})

	})
}

function isAdminInGroup() {
	return (config.params.vk_viewer_group_role === 'admin') ? true : false
}

function isStartInGroup() {
	return (config.params.vk_group_id) ? true : false
}