import React from 'react';
import { Panel, Group, Div, Button, PullToRefresh } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import CellBlock from '../components/CellBlock/CellBlock';
import NumberBlock from '../components/NumberBlock/NumberBlock';
import { findObjectById, dateToFromNowDaily } from '../func/utils';
import { getMissingGroups, getMissingUsers } from '../func/vk';
import { getData } from '../func/back';
import app from '../func/app';
import config from '../config';
import { startApp } from '../func/init';


class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fetching: false
		};
	}

	goNextPage = () => {
		const { apiUsersData, apiGroupsData, timestamp, count, offset } = this.props
		getData(count, offset+count, timestamp, (response) => {
			const { data } = response
			const uids = apiUsersData.map(({id}) => { return id })
			const gids = apiGroupsData.map(({id}) => { return id })
			const NeedUids = data.map(({uid}) => { return uid })
			const NeedGids = data.map(({gid}) => { return gid })
			getMissingUsers(uids, config.access_token, NeedUids, (users) => {
				getMissingGroups(gids, config.access_token, NeedGids, (groups) => {
					app.setState((state, props) => ({
						data: [...state.data, ...data],
						apiGroupsData: (groups) ? [...state.apiGroupsData, ...groups] : [...state.apiGroupsData],
						apiUsersData: (users) ? [...state.apiUsersData, ...users] : [...state.apiUsersData],
						offset: state.offset + state.count
					}))
				})
			})
		})
	}

	onRefresh = () => {
		this.setState({
			fetching: true
		}, () => {
			startApp(() => {
				this.setState({
					fetching: false
				})
			})
		})
	}

	render() {
		let { fetching } = this.state
		let { data, apiUsersData, apiGroupsData } = this.props
		return (
			<Panel id={this.props.id}>
				<Header name="Hello"/>
				<PullToRefresh onRefresh={this.onRefresh} isFetching={fetching}>
					<div>
						{
							data.map((item) => {
								const { uid, gid, id, months, time } = item
								const user = findObjectById(apiUsersData, uid)
								const group = findObjectById(apiGroupsData, gid)
								return (
									<Group key={id} description={dateToFromNowDaily(time)}>
										<CellBlock
											title={group.name}
											verified={group.verified}
											photo_50={group.photo_50}
											href={`https://vk.com/club${group.id}`}
										/>
										<CellBlock
											title={`${user.first_name} ${user.last_name}`}
											verified={user.verified}
											online={user.online}
											online_mobile={user.online_mobile}
											href={`https://vk.com/id${user.id}`}
											photo_50={user.photo_50}
										/>
										<Div className="flex_center">
											<h1><NumberBlock type="reduction" number={months} text={['месяц', 'месяца', 'месяцев']}/></h1>
										</Div>
									</Group>
								)
							})
						}
						{
							<Div className="flex_center">
								<Button onClick={this.goNextPage} level="secondary">Ещё</Button>
							</Div>
						}
					</div>
				</PullToRefresh>
			</Panel>
		)
	}
}

export default Home;