/* Блок для вывода числа */
/*
	type - 
		separation (разделение по знакам), 
		reduction (сокращает 'тыс.', 'млн.', 'млрд.', 'трлн.', 'P', 'E', 'Z', 'Y')
	text - дополнительный текст (склоняется)
*/
import React from 'react';
import './style.css';

const digits = 2 //сокращать до 2 символов после запятой
const units = ['тыс.', 'млн.', 'млрд.', 'трлн.', 'P', 'E', 'Z', 'Y']; //названия сокращений

class NumberBlock extends React.Component {
	formatCount = (count) => {
		return String(count).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	}

	declOfNum = (number, titles) => {  
		const cases = [2, 0, 1, 1, 1, 2];  
		return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
	}

	renderNumber = (number, type, text) => {
		switch (type) {
			case "separation":
				return <div>{`${this.formatCount(number)}${(text) ? ' ' + this.declOfNum(number, text) : ''}`}</div>
			case "reduction":
				let decimal;
				for(let i = units.length-1; i >= 0; i--) {
					decimal = Math.pow(1000, i+1);
					if(number <= -decimal || number >= decimal) {
						return <div>{`${(number / decimal).toFixed(digits) + units[i]}${(text) ? ' ' + this.declOfNum(number, text) : ''}`}</div>
					}
				}
				return <div>{`${number}${(text) ? ' ' + this.declOfNum(number, text) : ''}`}</div>
			default:
				return <div>{`${this.formatCount(number)}${(text) ? ' ' + this.declOfNum(number, text) : ''}`}</div>
		}
	}

	render() {
		let { number, type, text } = this.props
		return (
			<div>{this.renderNumber(number, type, text)}</div>
		)
	}
}

export default NumberBlock;