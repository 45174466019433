import React from 'react';
import { View, ScreenSpinner, ConfigProvider, Root } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

//хелперы
import app from './func/app';

import { go } from './func/init';
import { devLog } from './func/utils';
import navigation from './func/navigation';

//стартовые панели
import Home from './panels/Home';

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scheme: "client_light", //тема оформления client_light, client_dark, bright_light, space_gray
			header: false, //показывает или скрывает хедер для отображения при кастомном хедере на десктопе
			userScope: [], //права пользователя
			isStartInGroup: false, //сервис запущен из группы
			isAdminInGroup: false, //пользователь администратор в сообществе
			goStartPanel: false, //показывать стартовый экран
			error: { //ошибка js
				show: false,
				type: null,
				message: null,
				error: null
			},
			//навигация
			navPanels: {
				activeStory: "components", //активный эпик вью
				activePanelHome: "Home", //активная панель
			},

			isLoad: false, //загрузка приложения
			popout: null, //всплывающие окна
			apiUsersData: [], //информация о пользователях из апи
			apiGroupsData: [], //информация о группах из апи
			data: [],
			timestamp: 0,
			offset: 0,
			count: 20,
		};
	}

	componentDidMount() {
		app.setState = (state, callback) => {
			this.setState(state, callback)
		}
		app.state = this.state
		go()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.data !== this.state) {
			app.state = {
				...this.state
			}
		}
	}

	onStoryChange = (e) => {
		navigation.setStory({ activeStory: e.currentTarget.dataset.story })
	}

	render() {
		devLog(this.state)
		let { isLoad, popout, navPanels, header, scheme, data, apiUsersData, apiGroupsData, timestamp, count, offset } = this.state
		let { activeStory, activePanelHome } = navPanels
		if (isLoad) { //сервис уже получил все даннные и загружен
			return (
				<ConfigProvider scheme={scheme} webviewType="vkapps">
					<Root activeView={activeStory}>
						<View header={header} id="components" popout={popout} activePanel={activePanelHome}>
							<Home 
								count={count}
								offset={offset}
								timestamp={timestamp}
								apiGroupsData={apiGroupsData}
								apiUsersData={apiUsersData}
								data={data}
								id="Home"
							/>
						</View>
					</Root>
				</ConfigProvider>
			);
		} else { // сервис ещё не получил все данные
			return <ScreenSpinner/>
		}
	}
}

export default App;