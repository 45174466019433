import connect from '@vkontakte/vk-connect';
import config from '../config';
import app from './app';
import { forEachPromise, chunk, unique } from './utils';
import { apiRequest } from './vkApiRequest';

//получение токена пользователя
export function getToken(scope, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppAccessTokenReceived': //токен получен.
					successCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				case 'VKWebAppAccessTokenFailed': //токен не получен.
					errorCallback(true);
					connect.unsubscribe(clb);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetAuthToken", {
		"app_id": config.app_id, 
		"scope": scope.join(',')
	});
}

//получение токена по текущим правам доступа пользователя
export function getStartToken(scope, callback) {
	let newScope = [];
	scope.forEach((item) => {
		if ( item !== "" && item !== "menu" && item !== "notify") { 
			newScope.push(item) 
		}
	})
	if (newScope.length > 0) {
		getToken(newScope, (response) => {
			callback(response)
		}, (error)=> {
			callback(false);
		})
	} else {
		callback(false)
	}
}

//расширяет права доступа пользователя
export function getAddScope(scope, callback) {
	let { userScope } = app.state
	let newScope = scope.concat(...userScope)

	getToken(unique(newScope), (response) => {
		callback(response)
	})
}

//пользователь разрешает писать сообществу
export function getAllowMessagesFromGroup(gid, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppAllowMessagesFromGroupResult': //пользователь разрешил отправлять себе сообщения от имени группы
					successCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				case 'VKWebAppAllowMessagesFromGroupFailed': //отмена
					errorCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				default:
				//
			}
		}
	}

	connect.subscribe(clb);
	connect.send("VKWebAppAllowMessagesFromGroup", { "group_id": gid });
}

//вызывает окно оплаты VK Pay для оплаты
export function getVkPay(amount, description, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppOpenPayFormResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppOpenPayFormFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenPayForm", {
		"app_id": config.app_id,
		"action": "pay-to-group",
		"params": {
			"amount": amount,
			"description": description,
			"group_id": config.group_id,
		}
	});
}

//получение информации о текущем пользователе
export function getUserInfo(callback) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppGetUserInfoResult':
					callback(e.detail.data);
				break;
				default:
					//
			}
		}
	}
	connect.subscribe(clb);
	connect.send('VKWebAppGetUserInfo', {});
}

//получение ключа доступа сообщества
// TODO ПРОВЕРИТЬ ПОЛУЧЕНИЯ КЛЮЧА ДОСТУПА НА message 
export function getCommunityAuthToken(gid, scope, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppCommunityAccessTokenReceived':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppCommunityTokenReceived':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppGetCommunityAuthTokenResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppGetCommunityAuthTokenFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				case 'VKWebAppCommunityAccessTokenFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetCommunityAuthToken", {"app_id": config.app_id, "group_id": gid, "scope": scope.join(',')});
}

//вызов нативного просмоторщика фотографий
//images - массив ссылок на фото
export function showImagesBox(images = [], start_index = 0, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppShowImagesFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppShowImages", { 
		start_index: start_index,
		images: images
	});
}

//выбор контакта из телефонной книги
export function getPhoneContacts(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppContactsDone':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppContactsClosed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenContacts", {});
}

//подписывается на обновления VKWebAppUpdateConfig
export function listenVKWebAppUpdateConfig(successCallback = () => {}) {
	// подписываемся на VKWebAppUpdateConfig
	connect.subscribe((e) => {
		switch (e.detail.type) {
			case 'VKWebAppUpdateConfig':
				successCallback(e.detail.data)
			break;
			default:
				//
		}
	});
}

//Вызов списка друзей пользователя
export function getFriends(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppGetFriendsResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppGetFriendsFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetFriends", {});
}

//iOS, Android
//Вызов камеры для сканирования QR 
export function webAppOpenQR(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppOpenQRResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppOpenQRFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenQR");
}

//подписывается на сообщество
export function webAppJoinGroup(group_id, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppJoinGroupResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppJoinGroupFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppJoinGroup", {"group_id": group_id});
}

/* VK API */
//получение данных о пользователях
export function apiGetUser(access_token, uids, callback) {
	let arr = chunk(uids, 500);
	let arrToSend = [];
	forEachPromise(arr, (item) => {
		return new Promise((resolve, reject) => {
			try {
				apiRequest("users.get",{
					user_ids: item.join(','),
					fields: config.userFields.join(',')
				}, access_token, (resp) => {
					arrToSend.push(...resp)
					resolve()
				})
			} catch (err) {
				console.log('Ошибка users.get 584', err)
			}
		})
	}).then(() => {
		callback(arrToSend)
	});
}

//получение данных о группах
export function apiGetGroupById(access_token, gids, callback) {
	let arr = chunk(gids, 500);
	let arrToSend = [];
	forEachPromise(arr, (item) => {
		return new Promise((resolve, reject) => {
			try {
				apiRequest("groups.getById",{
					group_ids: item.join(','),
					fields: config.userFields.join(',')
				}, access_token, (resp) => {
					arrToSend.push(...resp)
					resolve()
				})
			} catch (err) {
				console.log('Ошибка groups.getById 584', err)
			}
		})
	}).then(() => {
		callback(arrToSend)
	});
}

//TODO Сделать получение всех групп
//получение групп пользователя
export function apiGetGroups(access_token, user_id, callback) {
	apiRequest("groups.get",{
		user_id: user_id,
		extended: 1,
		fields: config.groupFields.join(','),
		offset: 0,
		count: 1000,
	}, access_token, (response) => {
		callback(response)
	})
}

//получение данных по недостающим пользователям 
// availableUids - массив пользователей которые уже есть 
// needUids - массив пользователей по которым нужно получить данные
export function getMissingUsers(availableUids = [], access_token, needUids = [], callback = () => {}) {
	let uids = [];

	needUids.forEach((uid) => {
		if (availableUids.indexOf(uid) === -1) {
			availableUids.push(uid)
			uids.push(uid)
		}
	})

	if (uids.length > 0) {
		apiGetUser(access_token, unique(uids), (apiResponse) => {
			callback(apiResponse)
		})
	} else {
		callback(false)
	}
}

//получение данных по недостающим группам 
// availableGids - массив групп которые уже есть 
// needGids - массив групп по которым нужно получить данные
export function getMissingGroups(availableGids = [], access_token, needGids = [], callback = () => {}) {
	let gids = [];

	needGids.forEach((gid) => {
		if (availableGids.indexOf(gid) === -1) {
			availableGids.push(gid)
			gids.push(gid)
		}
	})

	if (gids.length > 0) {
		apiGetGroupById(access_token, unique(gids), (apiResponse) => {
			callback(apiResponse)
		})
	} else {
		callback(false)
	}
}

//поиск с пагинацией
export function newsfeedSearch(access_token, q = "", count, start_from = false, callback = () => {}) {
	if (start_from) {
		apiRequest("newsfeed.search",{
			q: q,
			extended: 1,
			fields: config.userFields.join(','),
			start_from: start_from,
		}, access_token, (response) => {
			callback(response)
		})
	} else {
		apiRequest("newsfeed.search",{
			q: q,
			extended: 1,
			fields: config.userFields.join(',')
		}, access_token, (response) => {
			callback(response)
		})		
	}
}

//проверяет подписан ли пользователь на сообещство
export function groupIsMember(access_token, group_id, user_id, callback = () => {}) {
	apiRequest("groups.isMember",{
		group_id: group_id,
		user_id: user_id
	}, access_token, (response) => {
		callback(response)
	})
}
