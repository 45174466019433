let config = {
	app_id: null, //ид приложения
	admin_app: [152873202, 33873307], //админы
	group_id: null,//группа для принятия платежей
	params: null, //параметры запуска
	hash: null, //Хеш приложения при запуске
	userData: null, //Данные о пользователе vk api
	wsUrl: (process.env.NODE_ENV === 'development') ? 'wss://echo.websocket.org' : 'wss://echo.websocket.org', //url wss сервера
	backUrl: (process.env.NODE_ENV === 'development') ? 'https://feedadmin.kupiapp2.ru/api/index.php' : 'https://feedadmin.kupiapp2.ru/api/index.php', //url бекенда
	api_version: '5.108', //версия апи
	access_token: 'ca4009abca4009abca4009ab9dca2d8b97cca40ca4009ab97eb17c0257ab0d50f842c15', //ключ доступа апи
	desktopHeader: true, //кастомный хедер для десктопа
	groupChange: false, //другой сервис в группе. true если нужно загружать другие панели для сервиса запущенного в группе
	startPanel: true, //показывать стартовую панель. Если true будет 1 раз загружать стартовую панель, если сервис загружен не в группе.
	userFields: ['photo_50', 'online', 'verified', 'sex'], //данные которые нужно запрашивать у пользователей
	groupFields: ['verified', 'members_count'], //данные которые нужно запрашивать у групп
	mobileConsole: false, //подключить в режиме разработки мобильную консоль для отладки iOS
	mibileConsoleCDN: 'cdn.jsdelivr.net/npm/eruda', //CDN скрипта с мобиьной консолью
	prodError: false, // включить/выключить перехват ошибок на проде
	errorEnv: 'development', //при включеннном prodError работает либо в development либо в production
	localStorageСounter: false, //включает счётчик входов в приложение на основе localStorage
	set: function(data, callback = () => {}) { //смена конфига
		for (let key in data) {
			this[key] = data[key]
		}
		callback(true)
	}
}

export default config;