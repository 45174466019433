import app from './app';

let navigation = {
	map: {
		components: "activePanelHome",
	},
	history: {
		activeStory: "",
		activePanelHome: ["Home"],
		all: [],
	},
	getHystory: function() {
		const history = {
			...app.state.navPanels
		}
		return history
	},
	go: function(obj, callback = () => {}) {
		window.history.pushState({}, "", "")
		//window.history.replaceState({}, "", "")

		//записываем в историю перехода 
		Object.keys(obj).forEach((key) => {
			if (key !== "activeStory") {	
				if (navigation.history[key]) {
					navigation.history[key] = [...navigation.history[key], obj[key]]
				} else {
					navigation.history[key] = []
					navigation.history[key] = [...navigation.history[key], obj[key]]
				}
			}
		})
		navigation.history.activeStory = app.state.navPanels.activeStory

		navigation.history.all = [...navigation.history.all, navigation.getHystory()]

		app.setState({
			navPanels: {
				...app.state.navPanels,
				...obj
			}
		}, callback)
	},
	goBack: function() {
		let activeStory = navigation.history.activeStory
		let activePanel = navigation.map[activeStory]
		let activePanelName = navigation.history[activePanel][navigation.history[activePanel].length - 2]

		app.setState({
			navPanels: {
				...app.state.navPanels,
				[activePanel]: activePanelName
			}
		}, () => {
			navigation.history[activePanel] = [...navigation.history[activePanel].slice(0, -1)]
			navigation.history.activeStory = app.state.navPanels.activeStory

			navigation.history.all = [...navigation.history.all.slice(0, -1)]
		})
	},
	nativeGoBack: function() {
		let activeStory = navigation.history.activeStory
		let activePanel = navigation.map[activeStory]

		let target = navigation.history.all[navigation.history.all.length - 1]

		app.setState({
			navPanels: {
				...app.state.navPanels,
				...target
			}
		}, () => {
			if (navigation.history[activePanel].length > 1) {
				navigation.history[activePanel] = [...navigation.history[activePanel].slice(0, -1)]
			}
			navigation.history.activeStory = app.state.navPanels.activeStory

			navigation.history.all = [...navigation.history.all.slice(0, -1)]
		})
	},
	setStory: function(obj, callback) {
		window.history.pushState({}, "", "")


		navigation.history.all = [...navigation.history.all, navigation.getHystory()]

		navigation.history.activeStory = obj.activeStory
		app.setState({
			navPanels: {
				...app.state.navPanels,
				...obj
			}
		}, callback)
	},
	link: function(link) {
		const a = document.createElement('a');
		a.href = link;
		a.target = '_blank';

		a.dispatchEvent(new window.MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true
		}));
	}
}

export default navigation;