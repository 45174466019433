import config from '../config';
import 'whatwg-fetch'

export function sentReq(json_data, callback = () => {}, callback_error = () => {}) {
	let url = config.backUrl
	json_data.params = config.params
	
	fetch(url, {
		method: 'post',
		headers: {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: 'data=' + encodeURIComponent(JSON.stringify(json_data))
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		callback(data);
	})
	.catch(function (error) {
		callback_error(error)
	});
}

export function sentWss(connection, json_data) {
	json_data.params = config.params
	connection.send(JSON.stringify(json_data));
}

export function getData(count, offset, timestamp, callback = () => {}) {
	sentReq({command: "getData", count:count, offset:offset, timestamp:timestamp}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			console.log(response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}
